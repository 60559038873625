<template>
  <CustomModal @close="onClose">
    <template #title>
      <keep-alive>
        <component header :is="header" :coachInfo="coachInfo" :editAllowed="editAllowed" :edit="isEdit" @share="onShare" @favorite="onFavorite" @chat="onChat" @edit="onEdit" :isCoach="isCoach"/>
      </keep-alive>
    </template>
    <template #nav>
      <nav-tab-modal-tutor v-model="currentTab" :review-rating="reviewRating" :review-count="reviewCount" :edit="isEdit"/>
    </template>
    <component tab-contents v-model="lessonInfo" :is="tabContents" :coach-info="coachInfo" :edit="isEdit" :prefer-champ-request="preferChamps" @input:prefer-champ-request="v => preferChamps = v" @input:price="onInputPrice" :isCoach="isCoach"/>
  </CustomModal>
</template>
<script>
import { copyClipboard } from '@shared/utils/commonUtils';
import { getter } from '@shared/utils/storeUtils';
import _cloneDeep from 'lodash/cloneDeep';
import _pick from 'lodash/pick';
import { getHashValueFromRoute, makeHashValues, removeHashValues } from '@shared/utils/routerUtils';
import { addDuration } from '@shared/utils/timeUtils';
import CustomModal from './CustomModal.vue';
import TutorHeader from '@/views/components/coaching/modal-tutor/TutorHeader.vue';
import TutorHeaderMobile from '@/views/components/coaching/modal-tutor/TutorHeaderMobile.vue';
import NavTabModalTutor from '@/views/components/coaching/modal-tutor/NavTabModalTutor.vue';
import TabLesson from '@/views/components/coaching/modal-tutor/TabLesson.vue';
import TabChamp from '@/views/components/coaching/modal-tutor/TabChamp.vue';
import TabReview from '@/views/components/coaching/modal-tutor/TabReview.vue';
import TabChampEdit from '@/views/components/coaching/modal-tutor/TabChampEdit.vue';
import CoachCardEditButton from '@/views/components/coaching/modal-tutor/CoachCardEditButton.vue';
import { callEvent } from '@/utils/eventBusUtils';
import { ModalRecommend } from '@/views/components/LazyLoadings';

export default {
  components: { CoachCardEditButton, TabReview, TabChamp, TabLesson, TabChampEdit, NavTabModalTutor, TutorHeaderMobile, TutorHeader, CustomModal },
  name: 'ModalTutor',
  data: () => ({
    currentTab: 'lesson',
    isEdit: false,
    /**
     * @typedef {Pick<CoachDetail, 'coachId'|'enableDoubleBundleSale'|'enableTripleBundleSale'|'historyThumbnail'|'mainHistory'|'lessonProducts'>} CoachInfo
     */
    lessonInfo: /** @type{CoachInfo} */ {},
    preferChamps: /** @type{PreferChampRequest[]} */ [],
    coachInfo: /** @type{CoachDetail} */{},
  }),
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    coachId() {
      return this.coachInfo?.coachId ?? '';
    },
    reviewRating() {
      return this.coachInfo?.reviewRating ?? 0;
    },
    reviewCount() {
      return this.coachInfo?.reviewCount ?? 0;
    },
    isLiked() {
      return this.coachInfo?.isLiked ?? false;
    },
    isLogin: getter('auth', 'isLogin'),
    isCoach: getter('auth', 'isCoach'),
    userId: getter('auth', 'userId'),
    editAllowed() {
      return this.isCoach && this.coachInfo?.user?.userId === this.userId;
    },
    header() {
      const isMobile = this.matchedMediaDevice === 'M';
      return isMobile ? TutorHeaderMobile : TutorHeader;
    },
    tabContents() {
      if (this.isEdit && this.currentTab === 'champ') return 'TabChampEdit';
      switch (this.currentTab) {
        case 'lesson':
          return 'TabLesson';
        case 'champ':
          return 'TabChamp';
        case 'review':
          return 'TabReview';
        default:
          return null;
      }
    },
  },
  methods: {
    async init() {
      this.coachInfo = this.options?.coachInfo;
      this.lessonInfo = _pick(_cloneDeep(this.coachInfo), ['coachId', 'enableDoubleBundleSale', 'enableTripleBundleSale', 'historyThumbnail', 'mainHistory', 'lessonProducts']);
      this.preferChamps = await this.getPreferChamps();
    },
    async recommendTutor() {
      // COG-477 코치 모달을 4번 닫았을 경우 추천 페이지로 유도
      const tutorModalCloseAmount = this.$services.cookie.getTutorModalCloseAmount();
      // 기존에 코치 추천 받았는지 여부를 조회했는지 여부를 확인
      const recommendQueried = this.$services.cookie.getTutorRecommendQueried();

      if (this.$services.auth.isLogin && !recommendQueried) {
        console.log('querying recommend history');
        try {
          const recommendation = await this.$services.coaching.getMyRecommendationCoaches();
          if (recommendation?.expiredDatetime) this.$services.cookie.setTutorRecommendExpire(recommendation?.expiredDatetime);
        } catch(err) {
          // ... blank
        }
        this.$services.cookie.setTutorRecommendQueried(true);
      }
      // 기존에 코치 추천받았는지 여부 확인
      const recommendExpiryDate = this.$services.cookie.getTutorRecommendExpire();
      const currentTime = new Date().getTime();
      const recommendExpired = recommendExpiryDate ? recommendExpiryDate.getTime() < currentTime : true;
      const lastRejected = this.$services.cookie.getTutorRecommendRejected();
      const past24HoursAfterReject = lastRejected ? addDuration(lastRejected, { hours: 24 }) < currentTime : true;

      // 추천을 안 받았거나
      // 추천을 받았으면 추천이 유효하지 않을 때
      // 추천 유도를 거절했다면 24시간이 지난 상태일 때
      if (recommendExpired && past24HoursAfterReject) this.$services.cookie.setTutorModalCloseAmount(tutorModalCloseAmount + 1);

      if (tutorModalCloseAmount >= 3) {
        // 버그: 현재는 새로고침을 해야만 $modal의 결과가 초기화된다. 인자가 같은 $modal의 값은 이후에도 같다??
        const modalRecommend = await this.$modal(ModalRecommend, {});
        if (modalRecommend) {
          // 예를 선택했을 경우
          await this.$router.push({ name: 'CoachMatching' });
        } else {
          // 아니오를 선택했을 경우, 카운트를 초기화하고 거절 시점을 기록한다
          this.$services.cookie.setTutorRecommendRejected();
          this.$services.cookie.setTutorModalCloseAmount(0);
        }
      }
    },
    async onClose() {
      this.$emit('close');
      const modalTutorId = getHashValueFromRoute(this.$route, 'modal-tutor-id');
      if (modalTutorId !== '') {
        const hash = removeHashValues(this.$route, ['modal-tutor-id']);
        if (Object.keys(hash).length > 0) {
          this.$router.push({ hash });
        } else {
          this.$router.push(this.$route.fullPath.split('#')[0]);
        }
      }
      if (!this.isCoach) await this.recommendTutor();
    },
    onShare() {
      const path = this.$router.resolve({ name: 'CoachHome', params: this.$route.params, hash: makeHashValues({ 'modal-tutor-id': this.coachId }) }).href;
      const url = [process.env.VUE_APP_HOME_URL, path].join('');
      copyClipboard(url);
      this.$toast('coaching.COACH_URL_COPIED_TO_CLIPBOARD');
    },
    async toggleLike() {
      await this.$services.coaching[this.isLiked ? 'unlikeCoach' : 'likeCoach'](this.coachId).then(() => { this.coachInfo.isLiked = !this.isLiked; });
      await callEvent('coach-list-get-list');
    },
    async onFavorite() {
      if (!this.isLogin) {
        await this.$services.auth.oAuthLogin(true);
        return;
      }
      await this.toggleLike();
    },
    async onChat() {
      if (!this.isLogin) {
        await this.$services.auth.oAuthLogin(true);
        return;
      }
      if (this.isCoach) return;
      await this.$services.chat.openChatWithCoach(this, this.coachId);
    },
    onEdit(value) {
      this.isEdit = value;
      if (!this.isEdit) return this.modifyCoachCard();

      this.init();
      this.currentTab = 'lesson';
    },
    async getTutorDetail() {
      const coachInfo = await this.$services.coaching.getTutorDetail(this.coachId);
      this.$set(this.options, 'coachInfo', coachInfo);
      this.init();
    },
    async modifyLesson() {
      const coachModify = /** @type{CoachDetailModify} */ {
        coachId: this.coachId,
        histories: this.lessonInfo?.mainHistory,
        lessonUpdateRequests: this.lessonInfo?.lessonProducts.map(lp => ({ lessonId: lp.lesson.lessonId, isHide: lp.isHide, price: lp.price })),
        enableDoubleBundleSale: this.lessonInfo?.enableDoubleBundleSale,
        enableTripleBundleSale: this.lessonInfo?.enableTripleBundleSale,
        historyImage: this.lessonInfo?.historyThumbnail,
      };
      if (!coachModify.historyImage || coachModify.historyImage === '') throw ['coaching.modifyCoach', 'customNoImage'];
      await this.$services.coaching.modifyCoach(coachModify);
    },
    async modifyChampion() {
      await this.$services.coaching.modifyUserLolChamps({ preferChampions: this.preferChamps });
    },
    async modifyCoachCard() {
      // TODO: 챔프 업데이트 바로 안되는 이슈, 코치 정보 수정 안되는 이슈
      await Promise.all([this.modifyLesson(), this.modifyChampion()]);
      // await sleep(300);
      window.location.reload();
      // await this.getTutorDetail();
    },
    async getPreferChamps() {
      const preferChamps = await this.$services.coaching.getPreferChamp({ coachId: this.coachId });
      return this.mapPreferChampForInput(preferChamps);
    },
    /** @param {PreferChamp[]} preferChamps */
    mapPreferChampForInput(preferChamps) {
      return preferChamps.map(pc => ({ championCode: pc.champion.code, lanes: pc.lanes }));
    },
    onInputPrice({ price, lessonProductId }) {
      const lessonIndex = this.lessonInfo?.lessonProducts.findIndex(lp => lp.lessonProductId === lessonProductId);
      if (lessonIndex === -1) return;
      const lesson = this.lessonInfo?.lessonProducts?.[lessonIndex];
      this.$set(this.lessonInfo?.lessonProducts, lessonIndex, { ...lesson, price });
    },
  },
  created() {
    const coachId = this.options?.coachInfo?.coachId ?? '';
    if (coachId !== '') this.init();
  },
  mounted() {
    /** GA 전자상거래 추적코드 */
    if (this.lessonInfo.lessonProducts) {
      const items = [];
      this.lessonInfo.lessonProducts.forEach(each => {
        items.push({
          item_id: each.lessonProductId,
          item_name: each.lesson.name,
          price: each.price * 10,
          item_brand: each.coachId,
          item_category: 'REALTIME_COACHING',
          currency: 'KRW',
        });
      });
      this.$gtag.event('view_item_list', {
        item_list_id: 'ModalTutor',
        item_list_name: 'ModalTutor',
        items,
      });
    }

    if (this.coachId) {
      this.$gtag.event('coach_modal_view', {
        event_category: 'modal_tutor',
        event_label: 'open',
        value: this.coachId,
      });
    }
  },
};
</script>
