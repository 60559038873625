<template>
  <color-button coach-card-edit-button class="tutor-edit-button" @click="isEdit = !isEdit">
    <span v-if="isEdit" class="tutor-chat-button-text">편집 완료</span>
    <span v-else class="tutor-chat-button-text">
      {{ isMobile ? '편집' : '코치 카드 편집' }}
    </span>
  </color-button>
</template>

<script>
import ColorButton from '@shared/components/common/ColorButton.vue';

export default {
  name: 'CoachCardEditButton',
  components: { ColorButton },
  model: {
    prop: 'value',
    event: 'click',
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isEdit: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('click', value);
      },
    },
    isMobile() {
      return this.matchedMediaDevice === 'M';
    },
  },
};
</script>
<style lang="less">
@import '~@/less/proj.less';

[coach-card-edit-button].tutor-edit-button { .flex;.flex-ai(center);.flex-jc(center); .wh(148, 48);.br(28); .bgc(#000); .c(#fff);
  &:hover { .bgc(rgba(59, 62, 67, 0.55)); }
}
</style>
