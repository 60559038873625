<template>
  <span checkbox-item @click.stop="click" :class="['no-drag', {[theme]: true, disabled}]">
    <i :class="{ checked: !checked }" v-if="reverse"><svg-check /></i>
    <i :class="{ checked }" v-else><svg-check /></i>
    <em><slot /></em>
  </span>
</template>

<script>
import SvgCheck from '@shared/graphics/svg-check.vue';

export default {
  name: 'CheckboxItem',
  components: { SvgCheck },
  model: {
    prop: 'selected',
    event: 'change',
  },
  props: {
    selected: {},
    value: {},
    // /** @type {'big' | 'small'} */
    theme: { type: String, default: 'big' },
    reverse: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    checked() {
      return Array.isArray(this.selected) ? this.selected.indexOf(this.checkedValue) !== -1 : this.selected === this.checkedValue;
    },
    checkedValue() {
      return this.value ?? true;
    },
    uncheckedValue() {
      return this.value ? null : false;
    },
  },
  methods: {
    click() {
      let result;
      if (this.disabled) return;
      if (Array.isArray(this.selected)) {
        result = this.selected;
        if (this.checked) {
          result.splice(this.selected.indexOf(this.checkedValue), 1);
        } else {
          this.selected.push(this.checkedValue);
        }
      } else {
        result = this.checked ? this.uncheckedValue : this.checkedValue;
      }
      this.$emit('change', result);
      if (this.$parent.validate) this.$parent.validate(result);
    },
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[checkbox-item] { .block; .rel; .-box; .vam; .pointer;
  em { .vam; }
  &.small {
    &.disabled { .o(0.3); cursor: default; }
    i {.block; .wh(14); .-a(rgba(255, 255, 255, 0.5)); .br(2); .pt(2);
      svg { .wh(8); .block; .mh-c;
        .fill-target { fill: transparent; }
      }
      &.checked { .bgc(rgba(255, 255, 255, 0.8)); }
      &.checked svg .fill-target { fill: #000; }
    }
  }
  &.big { .pl(30); .pv(2);
    i { .wh(24); .br(50%); .vam; .bgc(@c-base-gray); .abs; .lt;
      svg { .mh-c; .block; .wh(10, 100%);
        .fill-target { fill: #fff; }
      }
      &.checked svg .fill-target { fill: #000; }
    }
    span { .ib; .vam; }
  }
  &.default { .h(16);
    i {.ib; .wh(16); .-a(rgba(255, 255, 255, 0.5)); .br(3); .-a(@c-title-black); .pt(2); .o(.5);
      svg { .wh(10); .block; .mh-c;
        .fill-target { fill: transparent; }
      }
      &.checked { .bgc(rgba(255, 255, 255, 0.8)); .o(1);}
      &.checked svg .fill-target { fill: #2c81ff; }
    }
  }
}
</style>
