<template>
  <div tab-champ>
    <div class="intersect-container" v-if="isLogin && preferChampsIntersect.length >= 1 && !isCoach">
      <p class="intersect-head">{{ coachName }}님과의 선호 챔피언 매칭: {{ preferChampsIntersect.length }}</p>
      <div class="intersect-champs">
        <prefer-champ v-for="preferChamp in preferChampsIntersect" :prefer-champ="preferChamp" :key="preferChamp.champion.code" disabled value />
      </div>
    </div>
    <div v-for="lane in lanesWithChamp" :key="lane.value" class="lane-container" :data-lane="lane.value">
      <div class="lane-head">
        <div class="lane-icon">
          <component :is="laneIcons[lane.value]" />
        </div>
        <h2>{{ lane.text }}</h2>
      </div>
      <div class="lane-champs">
        <prefer-champ v-for="laneChamp in champsByLane.get(lane.value)" :key="`${lane.value}-${laneChamp.champion.code}`" :prefer-champ="laneChamp" disabled />
      </div>
    </div>
    <div v-if="preferChampsCoach.length === 0" class="empty-champ">
      <img src="/img/icon/icon-face-emoji.svg" />
      <em v-html="$t('_.PT_NO_DATA_SPECIALIST_CHAM_A')" />
      <p v-html="$t('_.PT_NO_DATA_SPECIALIST_CHAM_B')" />
    </div>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import _intersectionWith from 'lodash/intersectionWith';
import PreferChamp from '@/views/components/coaching/PreferChamp.vue';
import { LOL_LANE_SVG_ICON } from '@/constants/lol';
import { SvgLaneAll, SvgLaneMid, SvgLaneSupport, SvgLaneTop, SvgLaneJungle, SvgLaneBottom } from '@/views/graphics/lol-lane/LazyLoadings';

export default {
  lexicon: 'coaching',
  components: { PreferChamp, SvgLaneAll, SvgLaneMid, SvgLaneSupport, SvgLaneTop, SvgLaneJungle, SvgLaneBottom },
  data: () => ({
    preferChampsUser: /** @type{PreferChamp[]} */[],
    preferChampsCoach: /** @type{PreferChamp[]} */[],
    lanes: /** @type{QuestionCategoryLane[]} */[],
  }),
  props: {
    coachInfo: Specific,
  },
  computed: {
    ci() {
      return /** @type{CoachDetail} */ this.coachInfo;
    },
    coachName() {
      return this.ci?.user?.nickname;
    },
    // 사용자 선호 챔프와 코치 선호 챔프 중 겹치는 챔프
    preferChampsIntersect() {
      return _intersectionWith(this.preferChampsUser, this.preferChampsCoach, (a, b) => a?.champion?.code === b?.champion?.code);
    },
    userChampsCode() {
      const cc = new Map();
      this.preferChampsUser.forEach(c => cc.set(c?.champion?.code, true));
      return cc;
    },
    champsByLane() {
      const champsByLane = new Map();
      this.preferChampsCoach.forEach(c => {
        c.lanes.forEach(l => champsByLane.set(l, [...(champsByLane.get(l) || []), c]));
      });
      return champsByLane;
    },
    laneIcons() {
      return LOL_LANE_SVG_ICON;
    },
    isLogin() {
      return this.$store?.getters?.['auth/isLogin'] ?? false;
    },
    isCoach() {
      return this.$store?.getters?.['auth/isCoach'] ?? false;
    },
    lanesWithChamp() {
      return this.lanes.filter(l => this.champsByLane.get(l.value)?.length >= 1);
    },
  },
  methods: {
    async getPreferChamps() {
      const tasks = [this.isLogin ? this.$services.coaching.getPreferChamp({}, true) : Promise.resolve([]), this.$services.coaching.getPreferChamp({ coachId: this.ci?.coachId }, true), this.$services.coaching.getCategories('LOL_LANE'), true];
      const [preferChampsUser, preferChampsCoach, lanes] = await Promise.all(tasks);
      this.preferChampsUser = preferChampsUser;
      this.preferChampsCoach = preferChampsCoach;
      this.lanes = lanes;
    },
  },
  beforeMount() {
    this.getPreferChamps();
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[tab-champ] {
  .lane-container {.mb(12);}
  .lane-container + .lane-container {border-top: solid 1px #ebebf0;}
  .lane-head {.block;.h(40);.mt(26);.mb(26)}
  .lane-head > h2 {.c(#191919);display: inline-block;.fs(14);.bold;.mt(10)}
  .lane-head .lane-icon {float: left;}
  .lane-icon {.wh(40, 40);.bgc(#ebebf0);.br(12);.mr(6);.flex;.flex-ai(center);.flex-jc(center);}
  .lane-icon svg { .fill(#787781);}
  .intersect-champs, .lane-champs {.flex-gap(10px);}
  .intersect-container { .bgc(#ebebef);.p(9);.br(12);}
  .intersect-head {.fs(14);.mb(8);.bold;.c(#191919);}
  .empty-champ {text-align: center;.flex;.flex-column;.flex-ai(center);.flex-jc(center);.h(400);
    > img {.w(48);}
    > * {.block;}
    > * + * {.mt(12)}
    > em {.fs(16);.bold;.lh(23);.c(#000)}
    > p {.fs(13);.lh(19);.c(#787781)}
  }
  @media (@tp-down) {
    .intersect-champs, .lane-champs {.flex-gap(4px);}
  }
}
</style>
