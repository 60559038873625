/**
 * @type {Record<CoachTag, string>}
 */
export const TAG_COLOR = {
  OFFICIAL: 'red',
  PARTNER: 'orange',
  PRO: 'dark-magenta',
  OTP: 'dark-cyan',
  LOL_Q: 'dark-cyan',
  GCA: 'red',
  STREAMER: 'light-blue',
  default: 'dark-cyan',
  1: 'red',
  0: 'light-blue'
};

/**
 * @type {Record<CoachTag, string>}
 */
export const TAG_LOCAL = {
  OFFICIAL: 'MS_tag_official_S',
  PARTNER: 'MS_tag_partner_S',
  PRO: 'MS_tag_pro_S',
  OTP: 'MS_tag_otp_S',
  LOL_Q: 'MS_tag_lol_q_S',
  GCA: 'MS_tag_gca_S',
  STREAMER: 'MS_tag_streamer_S'
};

/** COACH STATUS */
export const COACH_STATUS_ONLINE = 'ONLINE';
export const COACH_STATUS_COACHING = 'COACHING';
export const COACH_STATUS_OFFLINE = 'OFFLINE';

// 시간: 할인율
// 0시간에 0% 할인, 1시간에 0% 할인, 2시간에 10%할인...
export const LESSON_DISCOUNT_RATE_PER_BUNDLE = [
  0, 0, 0.1, .15
];
