import { LESSON_DISCOUNT_RATE_PER_BUNDLE } from '@/constants/coach';
/**
 * @description
 * 번들 형태에 따른 수업 가격을 구하는 기능
 * @param {number} price
 * @param {number} bundleSize
 * @returns {number}
 */
export function getLessonPrice(price = 0, bundleSize = 1) {
  const discountRate = LESSON_DISCOUNT_RATE_PER_BUNDLE[bundleSize] ?? 0;
  // 소수점 이하는 절삭 처리
  return Math.floor(price * bundleSize * (1 - discountRate));
}

/**
 * 현재 코치 모달이 열려있는지 확인하는 기능
 * @returns {boolean}
 */
export function modalTutorExist() {
  return Boolean(document.body.querySelector('[modal].tutor'));
}
