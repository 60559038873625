<template>
  <simple-tabs tag="nav" class="tutor-modal-nav" v-model="currentTab" nav-tab-modal-tutor>
    <simple-tab tag="a" key="lesson">
      <span>수업</span>
    </simple-tab>
    <simple-tab tag="a" key="champ">
      <span>장인 챔피언</span>
    </simple-tab>
    <simple-tab tag="a" key="review" :disabled="edit">
      <span>리뷰
        <span v-show="reviewCount > 0">({{ reviewCount }})</span>
      </span>
      <template v-if="reviewRating > 0">
        <span v-if="matchedMediaDevice === 'M'" class="review-rating-text">{{ reviewRating }}</span>
        <rating-star :value="reviewRating" readonly v-else :star-size="12" />
      </template>
    </simple-tab>
  </simple-tabs>
</template>
<script>
import SimpleTabs from '@/views/components/coaching/SimpleTabs.vue';
import SimpleTab from '@/views/components/coaching/SimpleTab.vue';
import RatingStar from '@/views/components/general/RatingStar.vue';

export default {
  components: { RatingStar, SimpleTab, SimpleTabs },
  props: {
    value: {
      type: String,
      default: null,
    },
    reviewRating: {
      type: Number,
      default: 0,
    },
    reviewCount: {
      type: Number,
      default: 0,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentTab: {
      get() {
        return this.value;
      },
      set(currentTab) {
        this.$emit('input', currentTab);
      },
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[nav-tab-modal-tutor] {.flex;
  [simple-tab] {flex: 1;.max-w(100%);.h(46);text-align: center;.inline-flex;.flex-jc(center);.flex-ai(center);border-bottom: solid 1px #ebebf0;
    > span:not(.review-rating-text) {.c(#787781)}
  }
  [simple-tab].active {.bgc(#f5f5f7);border-bottom: solid 2px black;
    > span:not(.review-rating-text) {.c(#000)}
  }
  .review-rating-text {.c(#2c81ff);.ml(2);}
  // rating-star 영역만 pointer:cursor가 적용되지 않아 자연스럽지 않다는 의견이 있어 강제로 적용
  [rating-star] {.ml(2);cursor: pointer;
    * { cursor: pointer !important;}
  }
}
</style>
