<template>
  <div tutor-header class="mobile">
    <div class="control-area">
      <color-button class="button-favorite" type="light-gray" @click="onFavorite">
        <svg-favorite-active v-show="isLiked" />
        <svg-favorite-inactive v-show="!isLiked" />
      </color-button>
      <color-button class="button-share" type="light-gray" @click="onShare">
        <svg-share />
      </color-button>
    </div>
    <avatar class="tutor-avatar" :defaultImgType="'fruit'" :info="userInfo" dimension="120x120" userProfile />
    <div class="tutor-text-area">
      <lol-rank-text :value="rankCode" v-if="rankCode !== ''" tag="p" v-slot="{rankText}" class="tutor-rank">
        <slot name="tutor-rank">{{ rankText }}</slot>
      </lol-rank-text>
      <h1 class="tutor-name" :class="{ 'editable': editAllowed }">
        <slot name="tutor-name">{{ nickname }}</slot>
      </h1>
      <span class="lesson-count">수업 {{ lessonCount }}건</span>
      <span class="bookmark-count">코치 찜 {{ bookmarkCount }}건</span>
    </div>
    <CoachCardEditButton v-if="editAllowed" v-model="isEdit" />
    <div v-else class="chat-button-area">
      <primary-button color="red" class="tutor-chat-button" @click="onChat" :disabled="isCoach">
        <svg-msg />
      </primary-button>
    </div>
    <div class="tutor-badges">
      <FilledBadge v-for="tag in tags" :key="tag.code" :color="tagColor(tag.priority)" @click="findCoachByTag(tag.name)">{{ tag.name }}</FilledBadge>
    </div>
  </div>
</template>
<script>
import TutorHeader from './TutorHeader.vue';

export default {
  extends: TutorHeader,
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[tutor-header].mobile {.flex-wrap;.rel;
  .tutor-name {.fs(18); .mt(6);.max-w(135); .ellipsis(1);
    &.editable { .max-w(135) !important; }
  }
  .tutor-chat-button {.wh(48, 48);
    svg {.mr(0);}
  }
  .tutor-badges {.mt(16); .w(100%);
    [filled-badge] {.fs(11); .mr(4);}
    > p {.fs(11)}
  }
  .tutor-rank {.inline;}
  .control-area {.abs;.lt(0, -45);
    [color-button] {.wh(32, 32)}
    [color-button] + [color-button] {.ml(8);}
  }
  .chat-button-area {.wh(48, 48)}
  .tutor-avatar {.wh(60, 60);}
  .button-favorite {.pl(8);}
  .lesson-count {.ml(0);.mt(6);}
  .tutor-edit-button {.max-w(100)}
  @media (max-width: 395px) {
    .tutor-text-area { .mr(10);}
  }
  @media (max-width: 355px) {
    .chat-button-area {.mt(10px)}
  }
}
</style>
