<template lol-rank-text>
  <component :is="tag">
    <slot :rankText="rankText">
      {{ rankText }}
    </slot>
  </component>
</template>


<script>

export default {
  props: {
    value: {
      type: String,
      default: 'UNRANKED'
    },
    tag: {
      type: String,
      default: 'span'
    }
  },
  computed: {
    rankCode() {
      return this.value;
    },
    rankText() {
      if (this.rankCode === 'UNRANKED') return '';
      const code = `coaching.LOL_TIER_${this.rankCode}`;
      if (this.$te(code)) return this.$t(code);
      return this.rankCode;
    },
  }
};
</script>