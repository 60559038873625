<template>
  <div review-item>
    <avatar :info="user" :defaultImgType="'fruit'" dimension="104x104" userProfile />
    <div class="review-content">
      <pre>“{{ description }}”</pre>
      <p class="nickname">{{ nicknameBlocked }}님</p>
    </div>
    <div>
      <filled-badge color="dodger-blue">
        <rating-star readonly :value="rating" :star-size="8" />
      </filled-badge>
    </div>
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import Avatar from '@shared/components/common/Avatar.vue';
import FilledBadge from '@/views/components/coaching/FilledBadge.vue';
import RatingStar from '@/views/components/general/RatingStar.vue';

export default {
  components: { RatingStar, FilledBadge, Avatar },
  props: {
    lessonReview: Specific,
  },
  computed: {
    lr() {
      return /** @type{LessonReview} */this.lessonReview || {};
    },
    user() {
      return /** @type{LessonReview.user} */ this.lr?.user || {};
    },
    description() {
      return this.lr?.description || '';
    },
    nickname() {
      return this.lr?.user?.nickname || '';
    },
    rating() {
      return this.lr?.rating || 0;
    },
    nicknameBlocked() {
      const nicknameLength = this.nickname.length || 0;
      const blocks = '*'.repeat(nicknameLength - 2);
      const lastNickname = this.nickname.slice(-2);
      return [blocks, lastNickname].join('');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[review-item] {.flex;.min-h(100);.bgc(#ebebf0);.br(20);.p(20);
  [avatar] {.wh(52, 52);.mr(8);}
  // 반드시 min-w:0 을 해 줘야 줄바꿈이 일어남
  .review-content {flex: 1;.min-w(0);}
  .review-content > pre { white-space: pre-wrap;word-wrap: break-word;.fs(16);.c(#191919);}
  .nickname {.c(#787781);.fs(11);}
}
</style>
