<template>
  <component :is="tag" simple-tabs>
    <slot></slot>
  </component>
</template>
<script>
export default {
  props: {
    tag: {
      // DOM tag or Vue component
      type: [String, Object],
      default: 'ul'
    },
    value: {
      type: [String, Object, Number, Boolean],
      default: ''
    }
  },
  methods: {
    setValue(k) {
      this.$emit('input', k)
    }
  },
}
</script>