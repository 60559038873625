<template>
  <div selected-champ @click="onClick">
    <cdn-img :src="imageUrl" />
    <p>{{ koName }}</p>
  </div>
</template>

<script>
import CdnImg from '@shared/components/common/CdnImg.vue';
import Specific from '@shared/types/Specific';

export default {
  components: { CdnImg },
  props: {
    champ: {
      type: Specific,
      default: () => ({}),
    },
  },
  computed: {
    _champ() {
      return /** @type{LolChamp} */ this.champ;
    },
    imageUrl() {
      return this._champ?.imageUrl ?? '';
    },
    koName() {
      return this._champ.koName;
    },
  },
  methods: {
    onClick() {
      return this.$emit('click', this._champ?.code);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[selected-champ] { .c(#fff);.w(56);display: inline-block;.rel;.pointer;
  &::after {content: '';.abs;.wh(16, 16);.br(100%);.bgc(#fff);.rt(0, 0);.contain('@{img}/icon/close.svg')}
  [cdn-img] {.wh(100%, 56);.br(20); border: solid 2px #fff;}
  p {text-align: center;.fs(11);}
}
</style>
