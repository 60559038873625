const events = () => ({});

export const setEventHandler = (id, event) => {
  events[id] = event;
};

export const callEvent = async id => {
  if (!events[id]) return;
  const e = events[id];
  await e();
};

export const removeEventHandler = id => {
  events[id] = null;
}