<template>
  <div tutor-tooltip class="tooltip-wrap">
    <h3>{{ info.title }}</h3>
    <svg-tooltip v-popover.right.center="{ text: info.tooltip, maxWidth }" />
    <template v-if="subInfo.title">
      <label>{{ subInfo.title }}</label>
      <svg-tooltip v-popover.bottom.center="{ text: subInfo.tooltip, maxWidth }" />
    </template>
  </div>
</template>

<script>
import SvgTooltip from '@/views/graphics/svg-tooltip.vue';

export default {
  name: 'TutorTooltip',
  components: { SvgTooltip },
  props: {
    info: {
      type: Object,
      default: () => ({ title: '', tooltip: '' }),
    },
    subInfo: {
      type: Object,
      default: () => ({ title: '', tooltip: '' }),
    },
    maxWidth: {
      type: Number,
      default: 400,
    },
  },
};
</script>
<style lang="less">
@import '~@/less/proj.less';

[popover-container] { .z(6000); }
[tutor-tooltip] { .flex; .items-center; gap: 5px; .mb(10);
  > h3 { .ib; }
  > label { .ib; .ml(auto); }
}
</style>
