<template>
  <li @click="$emit('select', value)" champ-suggest-item>
    {{ text }}
  </li>
</template>

<script>
import Specific from '@shared/types/Specific';

export default {
  props: {
    champData: {
      type: Specific,
      default: () => {
      },
    },
  },
  computed: {
    champ() {
      return /** @type{LolChamp} */ this.champData ?? {};
    },
    text() {
      return this.champ?.koName;
    },
    value() {
      return this.champ?.code;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[champ-suggest-item] {.w(100%);.p(8, 12, 8, 12);.rel;
  &:hover {.bgc(#ebebf0);}
  &::after {content: '';.abs;.contain('@{img}/icon/ico_plus.svg');.rt(10, 8);.wh(20, 20)}
}
</style>
