<template>
  <!-- 모바일 버전 화면 -->
  <div lesson-bundle @click="onSelect" class="mobile">
    <point-icon shape="gray" :value="totalPrice" />
    <p class="bundle-hours">{{ bundleSize }}시간</p>
    <filled-badge v-if="discountRate > 0" color="black">{{ discountText }}</filled-badge>
  </div>
</template>

<script>
import LessonBundle from '@/views/components/coaching/modal-tutor/LessonBundle.vue';

export default {
  extends: LessonBundle,
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[lesson-bundle].mobile { .flex;.flex-ai(center);.br(12);
  .bundle-hours {.fs(18);.bold;flex-grow: 1;text-align: right;}
  [point-icon] {.h(32)}
}
</style>
