/**
 * @description
 * 아코디언 컴포넌트의 펼침효과를 자연스럽게 구현하기 위한 Vue Directive
 */
import Vue from 'vue';
import { easeOutQuad } from '@shared/modules/Easing';

const px = num => `${num}px`;

function collapseSection(element, duration, collapsedHeight) {
  // get the height of the element's inner content, regardless of its actual size
  const sectionHeight = Math.round(element.getAttribute('data-expand-height'));

  // temporarily disable all css transitions
  element.style.transition = '';
  element.style.overflowY = 'hidden';

  const onFinal = () => {
    element.style.height = px(0);
    // mark the section as "currently collapsed"
    element.setAttribute('data-collapsed', 'true');
    element.setAttribute('data-expand-in-progress', 'false');
  };
  element.setAttribute('data-expand-in-progress', 'true');

  let startTime = -1;
  const calculateFrame = timestamp => {
    if (startTime === -1) startTime = timestamp;
    const timeDiff = timestamp - startTime; // 0 ~ (diff)
    // const delta = timeDiff / duration; // 0~1
    // const deltaEased = easeQuadOut(delta);
    // const interpolator = interpolateString(px(sectionHeight), px(0));
    if (timeDiff < duration && element.getAttribute('data-collapsed') === 'false') {
      element.style.height = px(easeOutQuad(timeDiff, collapsedHeight, sectionHeight, duration));
      requestAnimationFrame(calculateFrame);
    } else onFinal();
  };
  requestAnimationFrame(calculateFrame);
}

function expandSection(element, duration) {
  // get the height of the element's inner content, regardless of its actual size
  const sectionHeight = Math.round(element.getAttribute('data-expand-height'));

  const stylesVisibility = [];
  element.childNodes.forEach((elChild, i) => {
    if (elChild.style) {
      stylesVisibility[i] = elChild.style.visibility;
      elChild.style.visibility = 'hidden';
    }
  });

  const enableVisibility = () => {
    element.childNodes.forEach((elChild, i) => {
      if (elChild.style) {
        elChild.style.visibility = stylesVisibility[i];
      }
    });
  };

  const onFinal = () => {
    element.style.height = px(sectionHeight);
    enableVisibility();
    element.style.transition = element.getAttribute('data-expand-transition');
    element.style.overflowY = element.getAttribute('data-expand-overflowY');

    // mark the section as "currently not collapsed"
    element.setAttribute('data-collapsed', 'false');
    element.setAttribute('data-expand-in-progress', 'false');
  };
  element.setAttribute('data-expand-in-progress', 'true');

  // temporarily disable all css transitions
  element.style.transition = '';
  element.style.overflowY = 'hidden';

  let startTime = -1;
  const calculateFrame = timestamp => {
    if (startTime === -1) startTime = timestamp;
    const timeDiff = timestamp - startTime; // 0 ~ (diff)
    // const delta = timeDiff / duration; // 0~1
    // const deltaEased = easeQuadOut(delta);
    // const interpolator = interpolateString('0px', px(sectionHeight));
    // requestAnimationFrame은 기본적으로 비동기이므로 여기서 마지막 처리를 해주어야함.
    if (timeDiff < duration && element.getAttribute('data-collapsed') === 'true') {
      element.style.height = px(easeOutQuad(timeDiff, sectionHeight, 0, duration));
      requestAnimationFrame(calculateFrame);
    } else onFinal();
  };
  requestAnimationFrame(calculateFrame);
}

function update(el, binding) {
  if (binding.value === null || !el.style) return;
  const { expand = false, duration = 300, collapsedHeight = 0 } = binding.value || {};
  const { expand: oldExpand = false } = binding.oldValue || {};
  // componentUpdate와 update의 주기가 다르다.
  // 하위 컴포넌트가 없는 상태에서 애니메이션을 시작하게 되면 실질적인 height는 0이 되어버린다.
  // 따라서 하위 vue 컴포넌트를 로드한 뒤에 height를 측정하도록 수정한다.
  Vue.nextTick(() => {
    if (expand !== oldExpand) {
      const elHeight = el.style.height;
      // height:auto;로 설정하여 효과에 사용할 본연의 크기를 찾는다.
      el.style.height = null;
      el.setAttribute('data-expand-height', el.scrollHeight);
      el.style.height = elHeight;
      el.setAttribute('data-collapsed', expand.toString());

      if (expand) expandSection(el, duration, collapsedHeight);
      else collapseSection(el, duration, collapsedHeight);
    }
  });
}

function inserted(el, binding) {
  if (binding.value === null) return;
  el.classList.add('v-expand-collapse');
  el.setAttribute('data-expand-transition', el.style.transition);
  el.setAttribute('data-expand-overflowY', el.style.overflowY);
  el.setAttribute('data-expand-in-progress', 'false');
  el.style.overflowY = 'hidden';
  const { expand = false } = binding.value || {};
  if (!el.style) return;
  if (!expand) el.style.height = px(0);

}

const VExpand = {
  inserted,
  update,
};

export default VExpand;
