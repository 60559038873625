<template>
  <div tutor-header>
    <avatar class="tutor-avatar" :info="userInfo" :defaultImgType="'fruit'" dimension="120x120" userProfile />
    <div class="tutor-text-area">
      <h1 class="tutor-name">
        <slot name="tutor-name">{{ nickname }}</slot>
      </h1>
      <div class="tutor-summary-area">
        <lol-rank-text :value="rankCode" v-if="rankCode !== ''" tag="span" v-slot="{rankText}" class="tutor-rank">
          <slot name="tutor-rank">{{ rankText }}</slot>
        </lol-rank-text>
        <span class="lesson-count">수업 {{ lessonCount }}건</span>
        <span class="bookmark-count">코치 찜 {{ bookmarkCount }}건</span>
      </div>
      <FilledBadge class="tag-filter" v-for="tag in tags" :key="tag.code" :color="tagColor(tag.priority)" @click="findCoachByTag(tag.name)">
        {{ tag.name }}
      </FilledBadge>
    </div>
    <div class="chat-button-area">
      <color-button class="button-favorite" type="light-gray" @click="onFavorite">
        <svg-favorite-active v-show="isLiked" />
        <svg-favorite-inactive v-show="!isLiked" />
      </color-button>
      <color-button class="button-share" type="light-gray" @click="onShare">
        <svg-share />
      </color-button>
      <CoachCardEditButton v-if="editAllowed" v-model="isEdit" />
      <primary-button v-else color="red" class="tutor-chat-button" @click="onChat" :disabled="isCoach">
        <svg-msg />
        <span class="tutor-chat-button-text">1:1채팅</span>
      </primary-button>
    </div>
  </div>
</template>

<script>
import Avatar from '@shared/components/common/Avatar.vue';
import PrimaryButton from '@shared/components/common/PrimaryButton.vue';
import ColorButton from '@shared/components/common/ColorButton.vue';
import { commaDecimal } from '@shared/utils/numberUtils';
import FilledBadge from '@/views/components/coaching/FilledBadge.vue';
import SvgMsg from '@/views/graphics/svg-msg.vue';
import SvgShare from '@/views/graphics/svg-share.vue';
import CoachCardEditButton from '@/views/components/coaching/modal-tutor/CoachCardEditButton.vue';
import LolRankText from '@/views/components/coaching/LolRankText.vue';
import { TAG_COLOR } from '@/constants/coach';
import SvgFavoriteActive from '@/views/graphics/svg-favorite-active.vue';
import SvgFavoriteInactive from '@/views/graphics/svg-favorite-inactive.vue';

export default {
  components: { SvgFavoriteInactive, SvgFavoriteActive, LolRankText, CoachCardEditButton, SvgShare, ColorButton, SvgMsg, PrimaryButton, Avatar, FilledBadge },
  lexicon: 'coaching',
  props: {
    coachInfo: {
      type: Object,
      default: () => ({}),
    },
    editAllowed: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    isCoach: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ci() {
      return /** @type{CoachDetail} */ this.coachInfo;
    },
    nickname() {
      return this.ci?.user?.nickname || '';
    },
    rankCode() {
      return this.ci?.rank?.code || '';
    },
    userInfo() {
      return this.ci?.user || {};
    },
    tags() {
      return this.ci?.tags || [];
    },
    isLiked() {
      return this.ci?.isLiked ?? false;
    },
    isEdit: {
      get() {
        return this.edit;
      },
      set(value) {
        this.$emit('edit', value);
      },
    },
    lessonCount() {
      return commaDecimal(this.ci?.lessonCount) || 0;
    },
    bookmarkCount() {
      return commaDecimal(this.ci?.bookmarkCount) || 0;
    },
  },
  methods: {
    onShare() {
      this.$emit('share');
    },
    onFavorite() {
      this.$emit('favorite');
    },
    onChat() {
      this.$emit('chat');
    },
    tagColor(tag) {
      return TAG_COLOR[tag] || TAG_COLOR.default;
    },
    findCoachByTag(tagName) {
      this.$router.push({ name: 'CoachFinder', query: { search: tagName } });
    },
  },
};
</script>
<style lang="less">
@import '~@/less/coaching.less';

[tutor-header] {.flex;.flex-ai(center);
  .tutor-avatar[avatar] {border: solid 2px #fff;.mr(12);}
  .tutor-name {.c(#fff);.mt(12);}
  .tutor-summary-area {.mt(10);}
  .tutor-rank {.c(#2c81ff);}
  .tutor-text-area {flex: 1; .mr(50);}
  .tutor-text-area {
    [filled-badge] {.mr(4)}
    .lesson-count { .c(#fff); .ml(8); .fs(12); }
    .bookmark-count { .c(#fff); .ml(8); .fs(12); }
  }
  .tutor-chat-button > span {.flex;.flex-ai(center);.flex-jc(center);}
  .tag-filter {.mt(10); .pointer}
  .chat-button-area [color-button]:not([coach-card-edit-button]) {.wh(32, 32);.flex;.flex-ai(center);.flex-jc(center);}
  .button-favorite {
    > svg {.wh(16, 16);}
    > svg { .fill(#a4a3ae); }
    &:hover {
      > svg { .fill(#191919); .stroke(none); }
    }
    &.active {
      > svg { .fill(#2c81ff); .stroke(none); }
    }
  }
  .button-share > svg {.wh(16, 16);.fill(rgba(164, 163, 174))}

  // desktop only
  .tutor-avatar[avatar] {.wh(80, 80);}
  .tutor-name {.fs(24);.lh(31);.max-w(290);}
  .tutor-rank {.fs(14);.mt(10);}
  .chat-button-area {.w(200);.flex;.flex-ai(center);.flex-jc(center);
    > * + * {.ml(8)}
  }
  .tutor-chat-button {.wh(148, 48);.br(28);}
  .tutor-chat-button svg {.mr(8);}
}
</style>
