<template>
  <!-- PC 버전 화면 -->
  <div lesson-bundle @click="onSelect">
    <div class="bundle-top">
      <p class="bundle-hours">{{ bundleSize }}시간</p>
      <filled-badge v-if="discountRate > 0" color="black">{{ discountText }}</filled-badge>
    </div>
    <point-icon shape="gray" :value="totalPrice" />
  </div>
</template>

<script>
import FilledBadge from '@/views/components/coaching/FilledBadge.vue';
import PointIcon from '@/views/components/coaching/PointIcon.vue';
import { LESSON_DISCOUNT_RATE_PER_BUNDLE } from '@/constants/coach';
import { getLessonPrice } from '@/utils/coachUtils';

export default {
  components: { PointIcon, FilledBadge },
  lexicon: 'coaching',
  props: {
    bundleSize: {
      type: Number,
      default: 1,
    },
    price: {
      type: Number,
    },
  },
  computed: {
    discountRate() {
      return LESSON_DISCOUNT_RATE_PER_BUNDLE[this.bundleSize] ?? 0;
    },
    discountText() {
      return `${this.discountRate * 100}% 할인`;
    },
    totalPrice() {
      return getLessonPrice(this.price, this.bundleSize);
    },
  },
  methods: {
    onSelect() {
      this.$emit('select', this.bundleSize);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[lesson-bundle] {.bgc(#fff);.p(12);box-sizing: border-box;border: solid 2px transparent;
  &.active {border: solid 2px #8300ff;
    [point-icon] {.bgc(#8300ff);.c(#fff)}
  }
  .bundle-hours + [filled-badge] {.ml(8);}
  &:not(:disabled) {.pointer}
  &:not(.mobile) { .br(12);text-align: right;
    > .bundle-top {.flex;.flex-jc(flex-end);.flex-ai(center);.mb(12);}
  }
}
</style>
