<template>
  <li lesson-product :class="[`mode-${mode}`]">
    <div class='head'>
      <checkbox-item v-if="edit" @change="onSelected" :selected="!lessonProduct.isHide" />
      <color-button @click="onHelpClick" type="gray">
        <svg-angle />
      </color-button>
      <em class="title-text">{{ title }}</em>
      <label v-if="edit" class="point-edit">
        <span>포인트</span>
        <text-input type="number" digit v-model.number="_price" />
      </label>
      <template v-else>
        <point-icon :value="price" shape="white" />
        <primary-button color="purple" @click="onBuyClick">수업 시작</primary-button>
      </template>
    </div>
    <ul class="description" v-if="mode === 'help'">
      <li v-for="(desc, i) in descriptions" :key="`${lessonProductId}-${i}`">
        <span>{{ desc.description }}</span>
      </li>
    </ul>
    <div class="buy" v-else-if="mode ==='buy'">
      <lesson-buy :lessonProduct="lessonProduct" :coachInfo="coachInfo" @close="$emit('close')" :isCoach="isCoach" />
    </div>
  </li>
</template>

<script>
import PrimaryButton from '@shared/components/common/PrimaryButton.vue';
import ColorButton from '@shared/components/common/ColorButton.vue';
import SvgAngle from '@shared/graphics/svg-angle.vue';
import Specific from '@shared/types/Specific';
import CheckboxItem from '@shared/components/common/CheckboxItem.vue';
import TextInput from '@shared/components/common/input/TextInput.vue';
import LessonBuy from '@/views/components/coaching/modal-tutor/LessonBuy.vue';
import expand from '@/directive/expand';
import PointIcon from '@/views/components/coaching/PointIcon.vue';

export default {
  components: { TextInput, CheckboxItem, LessonBuy, SvgAngle, ColorButton, PrimaryButton, PointIcon },
  directives: { expand },
  props: {
    lessonProduct: {
      type: Specific,
      default: () => ({}),
    },
    currentLessonId: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: '', // 'help', 'buy'
    },
    coachInfo: {
      type: Specific,
      default: () => ({}),
    },
    edit: {
      type: Boolean,
      default: false,
    },
    isCoach: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    lp() {
      return /** @type {LessonProduct} */ this.lessonProduct;
    },
    lessonProductId() {
      return this.lp?.lessonProductId || '';
    },
    title() {
      return this.lp?.lesson?.name || '';
    },
    price() {
      return this.lp?.price || 0;
    },
    descriptions() {
      return this.lp?.lesson?.description || [];
    },
    _price: {
      get() {
        return this.price;
      },
      set(price) {
        this.$emit('input:price', { price, lessonProductId: this.lessonProductId });
      },
    },
  },
  methods: {
    onHelpClick() {
      this.$emit('input:mode', 'help');
    },
    onBuyClick() {
      /** GA 전자상거래 추적코드 */

      const eventParams = {
        'item_list_name': 'ModalTutor',
        'item_list_id': 'ModalTutor',
        'value': this.lp.price * 10,
        'currency': 'KRW',
        'items': [{
          'item_id': this.lessonProductId,
          'item_name': this.lp.lesson.name,
          'price': this.lp.price * 10,
          'item_brand': this.lp.coachId,
          'item_category': 'REALTIME_COACHING',
          'quantity': 1,
        }],
      };
      this.$gtag.event('select_item', eventParams);
      this.$gtag.event('view_item', eventParams);

      this.$emit('input:mode', 'buy');
    },
    onSelected(isHide) {
      this.$emit('selected', !isHide);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[lesson-product] {list-style: none;
  > .head {.p(8, 12);.min-h(56);.bgc(#ebebf0);.br(20);.flex;.flex-ai(center);
    > * + * {.ml(8)}
    .title-text {flex-grow: 1;.fs(20);.c(#000);.bold;}
    [primary-button] {.br(12);.wh(128, 40);}
  }
  > .description, > .buy {.p(0, 12, 8, 12)}
  > .description {counter-reset: number;.mt(16);}
  > .description li {.rel;counter-increment: number;.ml(42);.fs(16);
    & + li {.mt(16)}
  }
  > .description li::before {.abs;content: counter(number) ' ';.l(-35);.wh(28, 28);.bgc(#000);.c(#fff);.br(100%);text-align: center;.lh(28)}
  > .buy {.bgc(#ebebf0);border-bottom-right-radius: 12px;border-bottom-left-radius: 12px;}
  &.mode-buy > .head { border-bottom-right-radius: 0; border-bottom-left-radius: 0;}
  &.mode-help > .head [svg-angle] {.t-r(180deg);}
  [checkbox-item].big { .pb(20);
    i { .bgc(#d5d5de); }
  }
  > .head > .point-edit {
    > * {vertical-align: middle;display: inline-block;}
    [text-input] {.ml(8);.rel;}
    [text-input]::before {content: 'UP';.abs;.rt(15, 50%);}
    input {.bgc(#fff);.w(120);text-align: right;.pr(40)}
  }
  @media (@tp-down) {
    > .head {
      .title-text {.fs(14);}
      [primary-button] {.br(16);.fs(14);.p(4, 6, 4, 6);.wh(69, 32);white-space: nowrap;}
    }
    > .description li {.fs(13);}
    > .description li::before {.t(-5)}
  }
  @media (max-width: 440px) {
    .head {.w(100%)}
    [point-icon] {
      .amount {.fs(15)}
      .currency {.fs(10)}
      img { .wh(20, 20)}
      flex-shrink: 0;
    }
    [primary-button] span {.fs(12);}
    [color-button].gray {.wh(30, 30);.lh(0);.pl(7);}
  }
}
</style>
