<template>
  <!-- 모바일 버전 화면 -->
  <div review-item class="mobile">
    <avatar :info="user" :defaultImgType="'fruit'" dimension="104x104" userProfile />
    <div class="review-content">
      <pre>“{{ description }}”</pre>
    </div>
    <p class="nickname">{{ nicknameBlocked }}님</p>
    <filled-badge color="dodger-blue">
      <rating-star readonly :value="rating" :star-size="8" />
    </filled-badge>
  </div>
</template>

<script>
import ReviewItem from '@/views/components/coaching/modal-tutor/ReviewItem.vue';

export default {
  extends: ReviewItem,
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[review-item].mobile {.rel;.p(8, 8, 40, 8);
  .nickname {.abs;.lb(8, 10)}
  [filled-badge] {.abs;.rb(8, 10)}
  .review-content pre {.fs(14);.lh(19)}
}
</style>
