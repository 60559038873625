<template>
  <div lesson-buy>
    <hr />
    <p class="hint-choose">수업시간을 선택해 주세요</p>
    <div class="bundles mobile" v-if="matchedMediaDevice === 'M'">
      <lesson-bundle-mobile :hours="1" :price="price" :class="{active: bundleSize === 1}" @select="setBundle" />
      <lesson-bundle-mobile v-if="ci.enableDoubleBundleSale" :bundleSize="2" :price="price" :class="{active: bundleSize === 2}" @select="setBundle" />
      <lesson-bundle-mobile v-if="ci.enableTripleBundleSale" :bundleSize="3" :price="price" :class="{active: bundleSize === 3}" @select="setBundle" />
    </div>
    <div class="bundles" v-else>
      <lesson-bundle :hours="1" :price="price" :class="{active: bundleSize === 1}" @select="setBundle" />
      <lesson-bundle v-if="ci.enableDoubleBundleSale" :bundleSize="2" :price="price" :class="{active: bundleSize === 2}" @select="setBundle" />
      <lesson-bundle v-if="ci.enableTripleBundleSale" :bundleSize="3" :price="price" :class="{active: bundleSize === 3}" @select="setBundle" />
    </div>
    <color-button type="dark" class="button-buy" @click="onBuy" :disabled="isCoach">{{ `${bundleSize}` }}시간 진행하기</color-button>
  </div>
</template>

<script>
import ColorButton from '@shared/components/common/ColorButton.vue';
import Specific from '@shared/types/Specific';
import LessonBundle from '@/views/components/coaching/modal-tutor/LessonBundle.vue';
import LessonBundleMobile from '@/views/components/coaching/modal-tutor/LessonBundleMobile.vue';
import { ModalCoupon } from '@/views/components/LazyLoadings';
import PointBuyModal from '@/views/components/coaching/PointBuyModal.vue';
import { CAUSE_LESSON_ORDERS } from '@/constants/payment';
import { getLessonPrice } from '@/utils/coachUtils';
import CoachReverificationModal from '@/views/components/coaching/CoachReverificationModal.vue';

export default {
  components: { LessonBundleMobile, ColorButton, LessonBundle },
  lexicon: 'coaching',
  props: {
    lessonProduct: {
      type: Specific,
      default: () => ({}),
    },
    coachInfo: {
      type: Specific,
      default: () => ({}),
    },
    isCoach: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    bundleSize: 1,
  }),
  computed: {
    lp() {
      return /** @type{LessonProduct} */ this.lessonProduct;
    },
    ci() {
      return /** @type{CoachDetail} */ this.coachInfo;
    },
    price() {
      return this.lp?.price ?? 0;
    },
    lessonProductId() {
      return this.lp?.lessonProductId ?? '';
    },
    isLogin() {
      return this.$store?.getters?.['auth/isLogin'] ?? false;
    },
  },
  methods: {
    setBundle(bundleSize) {
      this.bundleSize = bundleSize;
    },
    async onBuy() {
      if (!this.isLogin) {
        await this.$services.auth.oAuthLogin(true);
        return;
      }
      if (this.isCoach) return;

      const userInfoFromServer = await this.$services.auth.getMyInfoFromApi();
      if (!userInfoFromServer.verification.isVerified) {
        this.$modal(CoachReverificationModal);
        return;
      }

      let purchaseLessonArg;
      const neededPrice = getLessonPrice(this.price, this.bundleSize);
      try {
        /** GA 전자상거래 추적코드 */
        this.$gtag.event('begin_checkout', {
          value: neededPrice * 10,
          currency: 'KRW',
          items: [{
            item_id: this.lessonProductId,
            item_name: this.lp.lesson.name,
            price: this.lp.price * 10,
            quantity: this.bundleSize,
            item_brand: this.lp.coachId,
            item_category: 'REALTIME_COACHING',
            discount: ((this.lp.price * this.bundleSize) - neededPrice) * 10,
          }],
        });

        const coupon = /** @type{LessonCoupon} */ await this.$modal(ModalCoupon, {
          bundleSize: this.bundleSize,
          lessonProductId: this.lessonProductId,
          originalPrice: neededPrice,
        });
        purchaseLessonArg = { lessonProductId: this.lessonProductId, bundleSize: this.bundleSize, couponId: coupon?.coupon?.couponId };
        await this.$services.coaching.purchaseLesson(purchaseLessonArg);
        await this.$services.chat.openChatWithCoach(this, this.ci?.coachId, { isPurchaseEvent: true });

        /** GA 전자상거래 추적코드 */
        this.$gtag.event('purchase', {
          value: neededPrice * 10,
          currency: 'KRW',
          items: [{
            item_id: this.lessonProductId,
            item_name: this.lp.lesson.name,
            price: this.lp.price * 10,
            quantity: this.bundleSize,
            item_brand: this.lp.coachId,
            item_category: 'REALTIME_COACHING',
            discount: ((this.lp.price * this.bundleSize) - neededPrice) * 10,
          }],
        });

        this.$emit('close');
      } catch (err) {
        if (err?.[1] === 'NOT_SUFFICIENT_CASH') {
          console.error('cash not sufficient');
          this.$toast(err, { type: 'fail' });
          this.$store.commit('point/setPointBuyCause', { type: CAUSE_LESSON_ORDERS, purchaseLessonArg, neededPrice, coachId: this.ci?.coachId });
          // this.$modal(PointBuyModal);
          alert('포인트 충전이 불가합니다.');
        } else {
          console.error(err);
          throw err;
        }
      }
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[lesson-buy] {
  > hr { border-top: solid 1px rgba(0, 0, 0, 0.1);.pt(8);}
  > .hint-choose { .mb(12); }
  > .hint-choose { .mb(12); }
  .bundles:not(.mobile) {.flex;.w(100%);
    [lesson-bundle] + [lesson-bundle] {.ml(2)}
  }
  .bundles.mobile {
    [lesson-bundle] + [lesson-bundle] {.mt(12);}
  }
  .bundles [lesson-bundle] {flex: 1;.max-w(100%);}
  .button-buy {.m(0, auto);display: block;.mt(20);.mb(40)}
}
</style>
