<template>
  <div tab-review>
    <div class="no-review" v-if="totalCount === 0">
      등록된 리뷰가 없습니다.<br />코치님의 수업을 들어보세요!🎉
    </div>
    <template v-if="matchedMediaDevice === 'M'">
      <review-item-mobile v-for="review in reviews" :lessonReview="review" :key="review.lessonReviewId" />
    </template>
    <template v-else>
      <review-item v-for="review in reviews" :lessonReview="review" :key="review.lessonReviewId" />
    </template>
    <div class="scrollable-margin" v-show="hasNext" />
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import _debounce from 'lodash/debounce';
import ReviewItem from '@/views/components/coaching/modal-tutor/ReviewItem.vue';
import ReviewItemMobile from '@/views/components/coaching/modal-tutor/ReviewItemMobile.vue';

export default {
  components: { ReviewItemMobile, ReviewItem },
  data: () => ({
    reviewInfo: /** @type{PagedLessonReview} */{
      cursor: 0,
      hasNext: false,
      items: [],
      totalCount: 0,
      nextCursor: 1,
    },
  }),
  props: {
    coachInfo: Specific,
  },
  computed: {
    ci() {
      return /** @type{CoachDetail} */ this.coachInfo || {};
    },
    coachId() {
      return this.ci?.coachId || '';
    },
    reviews() {
      return /** @type{LessonReview[]} */ this.reviewInfo?.items || [];
    },
    hasNext() {
      return this.reviewInfo?.hasNext ?? false;
    },
    totalCount() {
      return this.reviewInfo?.totalCount ?? 0;
    },
  },
  methods: {
    async getReviews(hardReload = false) {
      const reviewInfo = await this.$services.coaching.getLessonReviews({ cursor: this.reviewInfo.cursor, coachId: this.coachId }, true);
      this.reviewInfo = {
        ...reviewInfo,
        items: hardReload ? reviewInfo.items : [...this.reviewInfo.items, ...reviewInfo.items],
      };
    },
    async getMoreReviews() {
      if (this.reviewInfo.hasNext) {
        this.$set(this.reviewInfo, 'cursor', this.reviewInfo.nextCursor);
        this.getReviews();
      }
    },
    /**
     * @description
     * 무한 스크롤 이벤트를 위한 스크롤 리스너
     * @param {Event<HTMLDivElement>} ev
     */
    onScroll(ev) {
      if (!ev?.target?.scrollHeight || !ev?.target?.offsetHeight) return;
      // 전체 스크롤 영역 - 초기 페이지 크기 = 실제 스크롤 최대 가능 영역
      const maxScrollH = ev.target.scrollHeight - ev.target.offsetHeight;
      const scrollH = ev?.target?.scrollTop;
      const scrollPercent = scrollH / maxScrollH;
      const diff = scrollPercent - (this.$_scrollPercent || 0);
      // 80% 이상 스크롤 했고, 아래로 스크롤 중일 때
      if (diff >= 0 && scrollPercent > 0.8) {
        this.getMoreReviews();
      }
      this.$_scrollPercent = scrollPercent;
    },
    onScrollDebounce: _debounce(function (ev) {
      this.onScroll(ev);
    }, 300, { trailing: true }),
  },
  beforeMount() {
    this.getReviews();
  },
  mounted() {
    this.$_modalScrollArea = document.querySelector('.panel .scroll-area');
    if (this.$_modalScrollArea) this.$_modalScrollArea.addEventListener('scroll', this.onScrollDebounce.bind(this));
  },
  beforeDestroy() {
    if (this.$_modalScrollArea) this.$_modalScrollArea.removeEventListener('scroll', this.onScrollDebounce.bind(this));
    this.$_modalScrollArea = null;
    this.$_scrollPercent = null;
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[tab-review] {
  [review-item] + [review-item] {.mt(12)}
  .scrollable-margin { .h(100); }
  .no-review { .bgc(#ebebf0);.wh(100%, 240);.c(#1f2226);.fs(16);.br(20);text-align: center;.flex-center;}
}
</style>
