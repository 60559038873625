<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.36 29.29" width="38.36" height="29.29" :fill="color" svg-check>
    <polygon points="3.82 12.45 0 16.2 12.86 29.29 38.37 3.79 34.58 0 12.89 21.68 3.82 12.45" class="fill-target"/>
  </svg>
</template>

<script>
export default {
  name: 'SvgCheck',
  props: { color: { type: String, default: '#000' } },
};
</script>
