<template>
  <div tab-lesson>
    <tutor-tooltip v-if="edit" :info="{ title:'수업유형', tooltip: 'coaching.tooltip.lesson' }" :sub-info="{ title: '1회 금액', tooltip: 'coaching.tooltip.price' }" />
    <ul>
      <lesson-product v-for="lessonProduct in lessonProducts"
        :key="lessonProduct.lessonProductId"
        :lesson-product="lessonProduct"
        :mode="modes[lessonProduct.lessonProductId]"
        :coachInfo="coachInfo"
        :edit="edit"
        @selected="onLessonSelected(lessonProduct, $event)"
        @input:mode="onModeChange(lessonProduct.lessonProductId, $event)"
        @input:price="onPriceChange"
        :isCoach="isCoach"
      />
    </ul>
    <tutor-tooltip v-if="edit" :info="{ title: '수업시간', tooltip: 'coaching.tooltip.hour' }" />
    <ul :class="{ left: edit }" class="available-bundles">
      <li v-if="ci.enableDoubleBundleSale || edit" :class="{ edit }" class="available-bundle">
        <checkbox-item v-if="edit" v-model="info.enableDoubleBundleSale" @selected="onSelected" />
        <span>2시간 연속 코칭</span>
        <filled-badge color="black">10% 할인</filled-badge>
      </li>
      <li v-if="ci.enableTripleBundleSale || edit" :class="{ edit }" class="available-bundle">
        <checkbox-item v-if="edit" v-model="info.enableTripleBundleSale" @selected="onSelected" />
        <span>3시간 연속 코칭</span>
        <filled-badge color="black">15% 할인</filled-badge>
      </li>
    </ul>
    <hr class="divider">
    <template v-if="edit">
      <tutor-tooltip :info="{ title: '코치 썸네일', tooltip: 'coaching.tooltip.thumbnail' }" />
      <div class="tutor-history edit">
        <!--        <image-selector :uploaded-image="historyThumbnail" @select="uploadImage"><img slot src="/img/icon/ico_edit.svg" alt="edit"/></image-selector>-->
        <image-selector :uploaded-image="historyThumbnail" @select="uploadImage" />
        <tutor-tooltip v-if="edit" :info="{ title: '이력 내용', tooltip: 'coaching.tooltip.history' }" />
        <div v-for="(_, id) in info.mainHistory" :key="id" class="history-input">
          <text-input v-model="info.mainHistory[id]" :tabindex="`${id+1}`" ref="historyInput" />
          <color-button @click="addHistory(id)" type="light-gray" :color-only="false" :disabled="mainHistoryExceeded">
            <svg-plus-gray />
          </color-button>
          <color-button @click="removeHistory(id)" type="light-gray" :color-only="false" :disabled="info.mainHistory.length < 2">
            <svg-minus-gray />
          </color-button>
        </div>
      </div>
    </template>
    <div v-else class="tutor-history desc">
      <cdn-img :src="historyThumbnail" dimension="320x400" />
      <ul>
        <li v-for="(history, idx) in mainHistory" :key="`history-${idx}`">{{ history }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import CdnImg from '@shared/components/common/CdnImg.vue';
import CheckboxItem from '@shared/components/common/CheckboxItem.vue';
import ImageSelector from '@shared/components/common/ImageSelector.vue';
import TextInput from '@shared/components/common/input/TextInput.vue';
import ColorButton from '@shared/components/common/ColorButton.vue';
import TutorTooltip from '@/views/components/coaching/modal-tutor/TutorTooltip.vue';
import FilledBadge from '@/views/components/coaching/FilledBadge.vue';
import LessonProduct from '@/views/components/coaching/modal-tutor/LessonProduct.vue';
import SvgPlusGray from '@/views/graphics/svg-plus-gray.vue';
import SvgMinusGray from '@/views/graphics/svg-minus-gray.vue';

export default {
  components: { SvgMinusGray, SvgPlusGray, ColorButton, TextInput, TutorTooltip, ImageSelector, CheckboxItem, CdnImg, FilledBadge, LessonProduct },
  lexicon: 'coaching',
  model: {
    prop: 'info',
    event: 'selected',
  },
  props: {
    coachInfo: {
      type: Object,
      default: () => ({}),
    },
    info: {
      type: Object,
      default: () => ({}),
    },
    edit: {
      type: Boolean,
      default: false,
    },
    isCoach: {},
  },
  data: () => ({
    modes: {},
    lessonInfo: {},
  }),
  computed: {
    ci() {
      return /** @type{CoachDetail} */ this.coachInfo || {};
    },
    lessonProducts() {
      return this.edit ? this.info.lessonProducts : this.filteredLessonProducts;
    },
    filteredLessonProducts() {
      return /** @type{LessonProduct[]} */ this.ci.lessonProducts?.filter(lp => !lp.isHide) ?? [];
    },
    historyThumbnail() {
      return this.ci?.historyThumbnail || '';
    },
    mainHistory() {
      return this.ci?.mainHistory || [];
    },
    mainHistoryExceeded() {
      return this.info?.mainHistory?.length >= 20;
    },
  },
  methods: {
    onModeChange(lessonProductId, mode) {
      this.modes = this.modes[lessonProductId] === mode ? {} : { [lessonProductId]: mode };
    },
    onSelected() {
      this.$emit('selected', this.info);
    },
    onLessonSelected(lessonProduct, isHide) {
      this.$set(lessonProduct, 'isHide', isHide);
      this.onSelected();
    },
    resetImage(reset) {
      reset();
      this.info.historyThumbnail = '';
    },
    async uploadImage({ file, resolve }) {
      const { key: image } = await this.$services.aws.upload(file);
      resolve();
      this.info.historyThumbnail = image;
    },
    async onFocus() {
      await this.$nextTick();
      const index = this.info.mainHistory.length - 1;
      const focusComponent = this.$refs.historyInput[index];
      focusComponent?.$el.querySelector('input').focus();
    },
    addHistory(id) {
      const { mainHistory } = this.info;
      if (this.mainHistoryExceeded) return;
      mainHistory.splice(id + 1, 0, '');
      this.$set(this.info, 'mainHistory', mainHistory);
      this.onFocus();
    },
    removeHistory(id) {
      this.info.mainHistory = this.info.mainHistory.filter((_, i) => i !== id);
      this.onFocus();
    },
    onPriceChange(arg) {
      console.log(arg);
      this.$emit('input:price', arg);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[tab-lesson] { .pb(50);
  [lesson-product] { .mt(10);
    & + [lesson-product] { .mt(16); }
    &:last-child { .mb(16); }
  }
  .available-bundles { .mb(21);
    &:not(.left) { .tl; }
    &.left { .tl; }
  }
  .available-bundles > li { .ib; .rel;
    [checkbox-item] { .inline; }
    & + .edit { .ml(30); }
    &:not(.edit) { .ml(30);
      &:before { .ib; .abs; .lt(-21, 2); content: ''; .bg('@{img}/icon/coaching/ico-check-purple.svg'); .wh(20, 20); }
    }
  }
  .available-bundle [filled-badge] {.ml(8)}
  .divider {.w(100%); .h(1); .m(24, 0); .bgc(#ebebf0); }
  .tutor-history { .min-h(200); .mb(20);
    &.desc {.flex;}
    & [cdn-img] { .min-w(160); .wh(160, 200);.br(20);display: inline-block;vertical-align: top;box-shadow: 0 0 30px 2px rgba(0, 0, 0, .3);}
    > ul { list-style: disc; display: inline-block;.ml(40);}
    > ul > li { line-break: anywhere;}
    [image-selector] { .wh(160, 200); .bgc(@c-base-gray); .mb(21);
      label .selected-image { .hf; object-fit: cover; }
      img[alt='edit'] { .pt(calc(100% - 20px)); }
      label::after {.contain('@{img}/icon/ico_edit.svg');content: '';.rb(0, 0);.wh(40, 40);.abs;.block;.pointer;}
    }
    [text-input] { .ib; .wf; }
    .history-input { .flex; .items-center; gap: 5px;
      & + .history-input { .mt(15); }
      button { .wh(32); }
    }
  }
  @media (@tp-down) {
    .available-bundle + .available-bundle {.mt(8)}
    .tutor-history {
      & [cdn-img] { .wh(120, 150);.mr(35); }
      > ul { .ml(5);}
    }
  }
}
</style>
