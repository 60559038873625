/**
 * @type {Record<LolLane, string>}
 */
export const LOL_POSITION_ICON = {
  TOP: '/img/icon/lol/ico_lol_top.svg',
  AD_CARRY: '/img/icon/lol/ico_lol_ad.svg',
  JUNGLE: '/img/icon/lol/ico_lol_jg.svg',
  MID: '/img/icon/lol/ico_lol_mid.svg',
  SUPPORT: '/img/icon/lol/ico_lol_sup.svg',
  NONE: '/img/icon/lol/ico_lol_unrank.png',
};

/**
 * @type {Record<LolLane, string>}
 */
export const LOL_POSITION_ICON_GREY = {
  TOP: '/img/icon/lol/ico_lol_top_grey.svg',
  AD_CARRY: '/img/icon/lol/ico_lol_ad_grey.svg',
  JUNGLE: '/img/icon/lol/ico_lol_jg_grey.svg',
  MID: '/img/icon/lol/ico_lol_mid_grey.svg',
  SUPPORT: '/img/icon/lol/ico_lol_sup_grey.svg',
  NONE: '/img/icon/lol/ico_lol_unrank_grey.png',
};

/**
 * @type {Record<LolLane, string>}
 */
export const LOL_POSITION_TEXT = {
  TOP: 'MC_lol_position_top',
  ALL: 'MC_lol_position_all',
  JUNGLE: 'MC_lol_position_jungle',
  MID: 'MC_lol_position_middle',
  AD_CARRY: 'MC_lol_position_bottom',
  SUPPORT: 'MC_lol_position_supporter'
};

/**
 * @type {Record<LolLane, string>}
 */
export const LOL_LANE_SVG_ICON = {
  ALL: 'SvgLaneAll',
  TOP: 'SvgLaneTop',
  JUNGLE: 'SvgLaneJungle',
  MID: 'SvgLaneMid',
  AD_CARRY: 'SvgLaneBottom',
  SUPPORT: 'SvgLaneSupport'
};
