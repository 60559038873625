<template>
  <component :is="componentName" svg-lol-lane/>
</template>
<script>
import { LOL_LANE_SVG_ICON } from '@/constants/lol';
import { SvgLaneTop, SvgLaneAll, SvgLaneSupport, SvgLaneBottom, SvgLaneMid, SvgLaneJungle } from '@/views/graphics/lol-lane/LazyLoadings';

export default {
  props: ['value'],
  components: {
    SvgLaneMid, SvgLaneTop, SvgLaneAll, SvgLaneSupport, SvgLaneBottom, SvgLaneJungle
  },
  computed: {
    componentName() {
      return LOL_LANE_SVG_ICON[this.value] ?? 'SvgLaneAll';
    }
  }
};
</script>
