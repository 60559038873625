<template>
  <div tab-champ-edit>
    <section v-for="lane in lolLanes" :key="lane.value" class="lane-section">
      <div class="lane-title">
        <div class="lane-icon">
          <svg-lol-lane :value="lane.value" />
        </div>
        <h1>{{ lane.text }}</h1>
        <template v-if="!editingCategory[lane.value]">
          <color-button type="dark" @click="() => onEdit(lane.value)">추가</color-button>
          <color-button type="dark" @click="() => onReset(lane.value)">초기화</color-button>
        </template>
        <template v-else>
          <color-button type="light-gray" @click="() => onStopEdit(lane.value)">완료</color-button>
        </template>
      </div>
      <div class="champ-suggest-wrap" v-if="editingCategory[lane.value]">
        <input-suggest class="champ-suggest" :text.sync="categoryKeyword[lane.value]" :suggest="(keyword) => onSuggestChamp(lane.value, keyword)" :get-item-prop="c => ({key: c.code, champData: c})" :on-select="arg => onChampSelect(lane.value, arg)" :item-tag="ChampSuggestItem">
          <template #no-suggestion>해당하는 챔프가 없습니다</template>
        </input-suggest>
      </div>
      <div class="champs">
        <selected-champ v-for="preferChamp in champsByLane[lane.value]" :champ="preferChamp.champ" :key="preferChamp.key" @click="champCode => onChampRemove(champCode, lane.value)" />
      </div>
    </section>
  </div>
</template>

<script>
import ColorButton from '@shared/components/common/ColorButton.vue';
import Specific from '@shared/types/Specific';
import SvgLolLane from '@/views/components/coaching/SvgLolLane.vue';
import InputSuggest from '@/views/components/coaching/InputSuggest.vue';
import ChampSuggestItem from './ChampSuggestItem.vue';
import SelectedChamp from '@/views/components/coaching/modal-tutor/SelectedChamp.vue';

export default {
  data: () => ({
    editingCategory: {},
    categoryKeyword: {},
  }),
  props: {
    preferChampRequest: {
      type: Specific,
      default: () => ([]),
    },
  },
  components: { SelectedChamp, InputSuggest, ColorButton, SvgLolLane },
  computed: {
    champsByCode() {
      return this.$store?.getters?.['lol/champsByCode'] ?? new Map();
    },
    lolLanes() {
      return /** @type{QuestionCategoryLane[]} */ this.$store?.state?.lol?.category?.LOL_LANE || [];
    },
    lolChamps() {
      return /** @type{LolChamp[]} */ this.$store?.state?.lol?.champs || [];
    },
    ChampSuggestItem: () => ChampSuggestItem,
    champsByLane() {
      const cbl = {};
      this.lolLanes.forEach(l => cbl[l.value] = []);
      this.preferChamps.forEach(c => {
        const noDupeLanes = Array.from(new Set(c?.lanes || []));
        for (const lane of noDupeLanes) {
          const champ = this.champsByCode.get(c?.championCode);
          const lanes = (c?.lanes ?? []).join(',');
          cbl[lane].push({ champ, key: `${c?.championCode}-${lanes}` });
        }
      });
      return cbl;
    },
    preferChamps: {
      get() {
        return /** @type{{ championCode: string, lanes: string[] }[]} */ this.preferChampRequest;
      },
      set(v) {
        return this.$emit('input:prefer-champ-request', v);
      },
    },
  },
  methods: {
    getChampsByLane(lane) {
      return /** @type{PreferChamp[]} */ this.champsByLane?.[lane] ?? [];
    },
    onEdit(lane) {
      this.$set(this.editingCategory, lane, true);
    },
    onStopEdit(lane) {
      this.$delete(this.editingCategory, lane);
    },
    onReset(lane) {
      this.preferChamps = this.preferChamps.map(preferChamp => ({ ...preferChamp, lanes: preferChamp.lanes.filter(l => l !== lane) })).filter(preferChamp => preferChamp.lanes.length >= 1);
    },
    async onSuggestChamp(lane, keyword) {
      const re = new RegExp(keyword, 'ig');
      return this.lolChamps.filter(c => re.test(c.koName) || re.test(c.enName));
    },
    onChampSelect(lane, { setText, value }) {
      if (this.champsByLane?.[lane]?.length >= 3) throw ['coaching', 'TP_F_ADD_EXPERT_CHAMPION'];
      setText('');
      const preferChampIndex = this.preferChamps.findIndex(pc => pc.championCode === value);
      if (preferChampIndex === -1) {
        this.preferChamps.push({ championCode: value, lanes: [lane] });
        return;
      }
      const preferChamp = /** @type{PreferChampRequest} */ this.preferChamps[preferChampIndex];
      if (preferChamp.lanes.includes(lane)) return;
      const lanesAdded = [...preferChamp.lanes, lane];
      this.$set(this.preferChamps, preferChampIndex, { ...preferChamp, lanes: lanesAdded });
    },
    onChampRemove(champCode, lane) {
      const preferChampIndex = this.preferChamps.findIndex(pc => pc.championCode === champCode);
      const preferChamp = this.preferChamps[preferChampIndex];
      const lanesRemoved = preferChamp.lanes.filter(l => l !== lane);
      if (lanesRemoved.length === 0) {
        this.$delete(this.preferChamps, preferChampIndex);
        return;
      }
      this.$set(this.preferChamps, preferChampIndex, { ...preferChamp, lanes: lanesRemoved });
    },
  },
  async beforeMount() {
    this.$services.coaching.getLolChampsAll();
    this.$services.coaching.getCategories('LOL_LANE');
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[tab-champ-edit] {.bgc(#191919);.p(10);
  .lane-icon {.bgc(#ebebf0);.wh(40, 40);.br(12);text-align: center;
    [svg-lol-lane] {.mt(4);.fill(#787781);}
  }
  .lane-title {.flex;.flex-ai(center)}
  .lane-title > h1 {.fs(14);.c(#ebebf0);.ml(8);flex: 1; }
  .lane-section + .lane-section {.mt(40);}
  .champ-suggest-wrap {.flex;flex-direction: row-reverse;.mt(8);}
  .champ-suggest {.responsive-w(200);}
  .champ-suggest input[type='text'] {.bgc(#293039);.c(#fff);.h(40)}
  .champ-suggest .suggest-list {.bgc(#293039);.c(#fff);.t(40);.scroll;}
  .champ-suggest.list-visible input[type='text'] {border-bottom-left-radius: 0;border-bottom-right-radius: 0}
  .lane-section > .champs {.flex-gap(10px);.mt(10);}
}

</style>
