<template>
  <component :is="tag" simple-tab @click.prevent="onClick" :class="{ active }" :disabled="disabled" :aria-checked="active">
    <slot :active="active" :k="key"></slot>
  </component>
</template>

<script>

export default {
  name: 'SimpleTab',
  props: {
    tag: {
      // DOM element tag or Vue Component
      type: [String, Object],
      default: 'li',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    active() {
      return this.$parent.value === this.key;
    },
    key() {
      return this.$vnode?.key;
    },
  },
  methods: {
    onClick() {
      if (this.disabled) return;
      if (!this.$parent?.setValue) throw new Error('wrong parent component');
      this.$parent.setValue(this.key);
    },
  },
  mounted() {
    const isProperParent = (!!this.$parent.$el.attributes['simple-tabs']);
    if (!isProperParent) throw new Error('only SimpleTabs can be used as parent component for SimpleTab component');
  },
};
</script>

<style lang="less">
[simple-tab][disabled] { cursor: not-allowed }
</style>