<template>
  <div modal class="tutor">
    <div class="dim" @click="close"></div>
    <div class="panel" :class="size">
      <div class="header">
        <slot name="title" />
        <a class="close" @click="close">
          <svg-x />
        </a>
      </div>
      <slot name="nav"></slot>
      <div class="scroll-area">
        <slot />
      </div>
      <slot name="floating" />
    </div>
  </div>
</template>

<script>
import Modal from '@shared/components/common/Modal.vue';

export default {
  extends: Modal,
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[modal].tutor {.noto;
  // min height는 PC에서만 적용
  > .panel { .bgc(#fff);.responsive-w(720);.min-h(700);}
  > .panel > .header .close svg path { stroke: #fff; }
  > .panel > .header {.bg('@{img}/coaching/tutor-modal-bg.png'); background-size: cover;}
  > .panel > .scroll-area {.pt(20);.pb(20)}

  @media (@ml-up) {
    > .panel > .header {.max-h(235);.pb(32);.pt(30);}
  }
  @media (@ml-down) {
    > .panel > .header {.max-h(435);.p(60, 12, 20, 12);}
    > .panel > .scroll-area {.p(12, 12);}
    > .panel {.min-h(0);.br(0);}
  }
}
</style>
